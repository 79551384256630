<script>
import { required } from 'vuelidate/lib/validators'
import ConfirmationModal from '@/components/general/ConfirmationModal'
export default {
  components: { ConfirmationModal },
  name: 'CandidatureCard',
  props: {
    candidature: {
      type: Object,
      required
    },
    forceStatus: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      showConfirmationModal: false
    }
  },
  methods: {
    getCandidatureStatus () {
      return this.forceStatus || this.candidature.position.status
    }
  }
}
</script>
<template>
  <div class="candidature-card--container" :class="[getCandidatureStatus()]">
    <div class="candidature-card--left-wrapper">
      <div class="candidature-card--info-wrapper">
        <p class="lato bold">{{ candidature.position.title }}</p>
        <div class="candidature-card--company-info">
          <v-avatar size="24px">
            <v-img
              :src="candidature.position.company.logo"
              height="24px"
              width="24px"
            ></v-img>
          </v-avatar>
          <p class="lato">{{ candidature.position.company.name }}</p>
        </div>
      </div>
    </div>
    <div class="candidature-card--right-wrapper">
      <span
        class="candidature-card--status"
        v-if="
          forceStatus !== 'closed' ||
          $te(`global:position.status.${candidature.position.status}`)
        "
      >
        <v-icon size="14">{{ getCandidatureStatus() !== 'no_filter' ? 'mdi-list-status' : 'mdi-clock-outline' }}</v-icon>
        {{
          getCandidatureStatus() !== 'no_filter'
            ? $tc('global:steps.left', candidature.stepsLeft, {
                n: candidature.stepsLeft,
              })
            : $t(`applications.status:${candidature.position.status}`)
        }}
      </span>
      <div class="candidature-card--actions">
        <v-btn
          v-if="forceStatus !== 'closed' && getCandidatureStatus() !== 'no_filter'"
          :href="candidature.position.company.url"
          target="_blank"
          class="ml-2 bold"
          outlined
          :color="isAggregator ? getPrimaryColor : '#1200D3'"
          small
        >
          {{ $t('global:show.position') }}
        </v-btn>
        <v-btn @click="$emit('handleModalMessage')"
          v-if="forceStatus !== 'closed'"
          class="ml-2 bold"
          :color="isAggregator ? getPrimaryColor : '#1200D3'"
          dark
          small
        >
        <v-icon size="14" class="mr-2">{{ "mdi-forum" }}</v-icon>
        {{ $t('global:send.message') }}
        </v-btn>
        <v-btn @click="$emit('handleModalMessage')"
          v-if="forceStatus === 'closed'"
          class="bold history"
          outlined
          :color="isAggregator ? getPrimaryColor : '#1200D3'"
          dark
          small
        >
          <v-icon size="14" class="mr-2">mdi-forum</v-icon>
          {{ $t('global:history') }}
        </v-btn>
      </div>
    </div>
    <confirmation-modal
      :show="showConfirmationModal"
      :title="$t('candidature.progress.reject.modal:title')"
      :description="$t('candidature.progress.reject.modal:info')"
      :confirmText="$t('global:give.up')"
      :btnConfirmText="false"
      @confirm="$emit('confirmReject')"
      :cancelText="$t('global:cancel')"
      @cancel="showConfirmationModal = false"
    />
  </div>
</template>
<style lang="scss">
.candidature-card--container {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  padding: 19px 24px;
  text-align: left;
  &.closed {
    background: rgba(0, 0, 0, 0.06);
    .candidature-card--left-wrapper {
      opacity: 0.5;
    }
  }
  .candidature-card--left-wrapper {
    display: flex;
    .v-image {
      border-radius: 100px;
    }
    .candidature-card--info-wrapper {
      margin-left: 8px;
      p {
        margin-bottom: 0;
        &.bold {
          font-family: $lato;
          font-style: normal;
          font-size: 14px;
          color: #000;
        }
      }
      .candidature-card--company-info {
        display: flex;
        align-items: center;
        margin-top: 8px;
        p {
          font-family: $lato;
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          margin-bottom: 0;
          margin-left: 8px;
        }
      }
    }
  }
  .candidature-card--right-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    .v-btn {
      text-transform: unset;
    }
    .candidature-card--status {
      margin: 0 16px;
      padding: 4px 8px;
      max-height: 22px;
      border-radius: 100px;
      font-family: $lato;
      font-weight: 700;
      font-size: 12px;
      line-height: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(0, 0, 0, 0.1);
      color: rgba(0, 0, 0, 0.8);
      white-space: nowrap;
      margin-bottom: 8px;
      .v-icon {
        margin-right: 4px;
        color: rgba(0, 0, 0, 0.8);
      }
    }
    .candidature-card--actions {
      display: inherit;
    }
  }
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    .candidature-card--right-wrapper {
      flex-direction: column;
      align-items: flex-start;
      margin-top: 8px;
      width: 100%;
      .candidature-card--status {
        margin-left: 0;
      }
      .candidature-card--actions {
        margin-top: 8px;
        width: 100%;
        display: flex;
        .v-btn {
          width: 50%;
          &.history {
            width: 100%;
          }
        }
      }
    }
  }
}
</style>
